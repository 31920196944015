<template>
  <div class="w-full pt-5 pb-5">
    <template>
      <div class="flex items-center">
        <div class="border-r border-romanSilver h-6 flex">
          <BackButton
            label="Back"
            @onClick="
              $router.push({
                name: 'EssAppraisals',
                query: { activeTab: 'Direct Reports' },
              })
            "
            variant="secondary"
            class="ml-2"
          />
        </div>
        <h1 class="text-xl text-left font-extrabold mx-4">Appraisal Details</h1>
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </template>
    <template v-if="isLoading">
      <div style="height: 100%" class="mt-20">
        <loader size="xxs" :loader-image="false" />
      </div>
    </template>
    <template v-else>
      <template>
        <div class="p-5 flex flex-col shadow m-5 rounded-md gap-4">
          <template>
            <div class="flex justify-between items-center">
              <span
                class="font-bold text-lg leading-5 tracking-normal capitalize text-darkPurple"
              >
                {{ agreementData.title }}
              </span>
            </div>
          </template>
          <template>
            <div
              class="border border-dashed bg-white border-romanSilver rounded-md gap-4 p-3 mt-4"
            >
              <div
                class="flex justify-between items-center w-full gap-4 pb-2 cursor-pointer"
                @click="handleShowDetails"
              >
                <span
                  class="font-black text-xs leading-5 uppercase tracking-wider text-romanSilver"
                >
                  Performance Details
                </span>
                <span
                  class="w-9/12 border-t border-solid border-romanSilver h-0"
                />
                <Icon
                  :icon-name="showDetails ? 'chevronDown' : 'chevronRight'"
                  class-name="text-darkPurple mx-auto self-center"
                  size="xms"
                />
              </div>
              <div
                class="flex flex-col gap-2"
                :class="{ hidden: !showDetails }"
              >
                <div class="flex p-3 shadow rounded-md gap-2">
                  <Icon
                    size="s"
                    icon-name="doc-cycle"
                    class-name="text-romanSilver self-start pt-1"
                  />
                  <div class="flex flex-col">
                    <span
                      class="font-black leading-5 text-xs tracking-wide text-darkPurple uppercase"
                    >
                      {{ agreementData.cycle }}
                    </span>
                    <span
                      class="font-bold text-sm leading-6 tracking-wide text-jet uppercase"
                    >
                      {{ agreementData.description }}
                    </span>
                  </div>
                </div>
                <div class="flex flex-wrap">
                  <div
                    class="flex p-3 shadow rounded-md gap-2 w-2/5 mr-2 mt-2 flex-grow"
                  >
                    <Icon
                      size="xs"
                      icon-name="calendar"
                      class-name="text-romanSilver self-start pt-1"
                    />
                    <div class="flex flex-col">
                      <span
                        class="font-black leading-5 text-xs tracking-wide text-carrotOrange uppercase"
                      >
                        Period
                      </span>
                      <span
                        class="font-bold text-sm leading-6 tracking-wide text-jet uppercase"
                      >
                        {{
                          $DATEFORMAT(
                            new Date(agreementData.period.start),
                            "MMMM dd, yyyy"
                          )
                        }}
                        -
                        {{
                          $DATEFORMAT(
                            new Date(agreementData.period.end),
                            "MMMM dd, yyyy"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                  <div
                    class="flex p-3 shadow rounded-md gap-2 w-2/5 mt-2 flex-grow"
                    v-for="(cycle, index) in agreementCycles"
                    :key="index"
                    :class="cycle.active ? '' : 'opacity-40'"
                  >
                    <Icon
                      class-name="text-romanSilver self-start pt-1"
                      size="xs"
                      icon-name="reviewicon"
                    />
                    <div class="flex flex-col flex-grow">
                      <span
                        class="font-black leading-5 text-xs tracking-wide text-blueCrayola uppercase"
                      >
                        {{ cycle.name }}
                      </span>
                      <span
                        class="font-bold text-sm leading-6 flex tracking-wide text-jet uppercase"
                      >
                        {{
                          $DATEFORMAT(new Date(cycle.start), "MMMM dd, yyyy")
                        }}
                        -
                        {{ $DATEFORMAT(new Date(cycle.end), "MMMM dd, yyyy") }}
                        <span v-if="extendedCycle">
                          <span
                            class="ml-4 flex"
                            v-if="extendedCycle.appraisalCycleId === cycle.id"
                          >
                            <p
                              class="text-sm uppercase text-mediumSeaGreen italic"
                              style="margin-top: 0.15rem"
                            >
                              Extended
                            </p>
                            <Icon
                              class="ml-3"
                              icon-name="icon-info"
                              size="xs"
                              style="margin-top: -0.15rem"
                            />
                          </span>
                        </span>
                        <span
                          class="font-bold ml-6 text-xs leading-6 flex tracking-wide text-mediumSeaGreen uppercase flex cursor-pointer"
                          @click="viewCycle(cycle.id)"
                        >
                          View
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </template>
      <template>
        <div class="w-full px-5">
          <div class="w-full">
            <Card class="p-5 pt-0">
              <CardFooter
                reloadcard
                showcalendar
                :show-bulk-actions="showBulk"
                :bulk-options="bulkActionOptions"
                @action="performBulkAction($event)"
                @reload="reload()"
                @searchResult="handleSearch($event)"
              />
            </Card>
          </div>
          <div>
            <Table
              :headers="AppHeader"
              :items="employees"
              aria-label="advance Table"
              class="w-full py-2"
              :has-checkbox="true"
              :has-number="true"
              :loading="loadingTable"
              :pagination-list="metaData"
              style="width: 100%"
              id="printMe"
              @page="handlePage($event)"
              @itemsPerPage="handleItemsPerPage($event)"
              page-sync
              @rowSelected="displayRow($event)"
            >
              <template v-slot:item="{ item }">
                <div v-if="item.photo">
                  <div>
                    <img
                      class="mr-3"
                      :src="item.data.photo"
                      v-if="item.data.photo"
                      alt="user photo"
                      style="height: 30px; width: 30px; border-radius: 5px"
                    />
                    <div
                      style="height: 30px; width: 30px; border-radius: 5px"
                      class="text-blueCrayola mr-3 flex justify-center border text-center font-semibold pt-1"
                      v-else
                    >
                      {{ $getInitials(`${item.data.name}`) }}
                    </div>
                  </div>
                </div>
                <div
                  v-if="item.name"
                  class="flex flex-col whitespace-nowrap font-semibold text-sm leading-5"
                >
                  <span class="text-darkPurple capitalize">{{
                    item.data.name
                  }}</span>
                  <div class="flex">
                    <span
                      class="text-text-romanSilver uppercase"
                      style="font-size: 10px"
                      >{{ item.data.function }}</span
                    >
                    <span
                      class="text-blueCrayola uppercase ml-3"
                      style="font-size: 10px"
                      >{{ item.data.designation }}</span
                    >
                  </div>
                </div>
                <div v-if="item.selfAppraisal">
                  <span
                    class="text-left text-sm leading-6 font-normal text-darkPurple"
                    >{{ item.data.selfAppraisal }}</span
                  >
                </div>
                <div v-if="item.managerAppraisal">
                  <span
                    class="text-left text-sm leading-6 font-normal text-darkPurple"
                    >{{ item.data.managerAppraisal }}</span
                  >
                </div>
                <div v-if="item.status">
                  <div class="flex">
                    <div
                      class="flex flex-grow"
                      v-if="item.data.status === 'submitted'"
                    >
                      <Badge
                        :label="item.data.status"
                        variant="primary"
                        :background-color="`rgba(19, 181, 106, 0.15)`"
                        :color="'#13B56A'"
                        class="capitalize"
                        style="
                          margin-right: 12px;
                          font-size: 14px;
                          padding: 12px 10px;
                          border-radius: 5px;
                        "
                      />
                    </div>

                    <div
                      class="flex flex-grow"
                      v-if="item.data.status === 'pending'"
                    >
                      <Badge
                        label="Pending"
                        variant="primary"
                        :background-color="`rgba(233, 147, 35, 0.15)`"
                        :color="'rgba(233, 147, 35, 1)'"
                        class="capitalize"
                        style="
                          margin-right: 12px;
                          font-size: 14px;
                          padding: 12px 10px;
                          border-radius: 5px;
                        "
                      />
                    </div>

                    <div
                      class="flex flex-grow"
                      v-if="item.data.status === 'not submitted'"
                    >
                      <Badge
                        label="not submitted"
                        variant="primary"
                        :background-color="`rgba(234, 60, 83, 0.15)`"
                        :color="'#EA3C53'"
                        class="capitalize"
                        style="
                          margin-right: 12px;
                          font-size: 14px;
                          padding: 12px 10px;
                          border-radius: 5px;
                        "
                      />
                    </div>
                  </div>
                </div>
                <div v-if="item.approvedScore">
                  <span
                    class="text-left text-sm leading-6 font-normal text-darkPurple"
                    >{{ item.data.approvedScore }}</span
                  >
                </div>
                <div v-else-if="item.submitted" class="mr-4 text-center">
                  {{
                    item.data.submitted === ""
                      ? "---"
                      : $DATEFORMAT(
                          new Date(item.data.submitted),
                          "MMMM dd, yyyy"
                        )
                  }}
                </div>
                <div class="cursor-pointer" v-if="item.id">
                  <Menu left @click.stop="" top="-120" margin="29">
                    <template v-slot:title>
                      <Icon icon-name="more_icon" size="xs" />
                    </template>
                    <template>
                      <div
                        class="flex flex-col w-52 h-18 p-2 justify-start items-start cursor-pointer"
                      >
                        <div
                          class="hover:bg-ghostWhite w-full rounded-md"
                          @click="
                            $router.push({
                              name: 'EssPerformanceEmployeeAppraisals',
                              params: {
                                year: item.data.year,
                                userId: item.data.id,
                                cycle: item.data.cycleName,
                                goalId: item.data.goal,
                              },
                            })
                          "
                          v-if="item.data.status !== 'not submitted'"
                        >
                          <div
                            class="flex justify-start items-center p-2 gap-2"
                          >
                            <Icon
                              icon-name="icon-eye"
                              class-name="text-blueCrayola"
                              size="xs"
                            />
                            <span
                              class="text-darkPurple text-sm leading-5 font-normal"
                            >
                              Review
                            </span>
                          </div>
                        </div>
                        <div
                          class="hover:bg-ghostWhite w-full rounded-md cursor-pointer"
                        >
                          <div
                            class="flex justify-start items-center p-2 gap-2"
                            v-if="
                              item.data.managerAppraisal !== '---' &&
                              item.data.status !== 'submitted' &&
                              item.data.status !== 'mid_review'
                            "
                            @click="approveAppraisal(item.data.appraisalId)"
                          >
                            <Icon
                              icon-name="icon-check"
                              size="xms"
                              class-name="mr-2 w-5 h-5"
                            />
                            <span
                              class="text-darkPurple text-sm leading-5 font-normal"
                            >
                              Approve
                            </span>
                          </div>
                        </div>

                        <div
                          class="hover:bg-ghostWhite w-full rounded-md cursor-pointer"
                          @click="
                            $router.push({
                              name: 'EssEmployeeStatusReports',
                              params: {
                                id: item.data.id,
                                year: $route.params.year,
                              },
                            })
                          "
                        >
                          <div
                            class="flex justify-start items-center p-2 gap-2"
                          >
                            <Icon
                              icon-name="file-document"
                              class-name="text-carrotOrange"
                              size="xs"
                            />
                            <span
                              class="text-darkPurple text-sm leading-5 font-normal"
                            >
                              Performance Reports
                            </span>
                          </div>
                        </div>

                        <div
                          class="hover:bg-ghostWhite w-full rounded-md cursor-pointer"
                          v-if="item.data.status !== 'not submitted'"
                        >
                          <div
                            class="flex justify-start items-center p-2 gap-2"
                            @click="
                              getComments(item.data.agreementId, item.data.id)
                            "
                          >
                            <Icon
                              icon-name="comment-outline"
                              class-name="text-carrotOrange"
                              size="xs"
                            />
                            <span
                              class="text-darkPurple text-sm leading-5 font-normal"
                            >
                              Comment
                            </span>
                          </div>
                        </div>
                      </div>
                    </template>
                  </Menu>
                </div>
              </template>
            </Table>
          </div>
        </div>
      </template>
    </template>

    <RightSideBar
      v-if="commentModal"
      @submit="sendComment"
      @close="commentModal = false"
      submit="Save"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <span class="text-darkPurple font-bold text-lg leading-5 p-0"
          >Comments</span
        >
      </template>
      <div class="w-full flex">
        <div class="flex flex-col w-full" style="min-height: 70vh">
          <CommentsBox
            :comments="allComments"
            @markRead="markAsRead($event)"
            @deleteComment="deleteComments($event)"
            :loading="loadComments"
          />
          <div class="-mb-12" style="margin-top: auto">
            <CTextarea
              placeholder="--Message--"
              variant="primary"
              class="mt-2"
              value=""
              :row="8"
              :height="90"
              :col="30"
              :input="null"
              v-model="comment"
            />
          </div>
        </div>
      </div>
    </RightSideBar>
  </div>
</template>

<script>
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import CTextarea from "@scelloo/cloudenly-ui/src/components/text-area";
import Badge from "@scelloo/cloudenly-ui/src/components/badge";
import Card from "@scelloo/cloudenly-ui/src/components/card";
import Menu from "@scelloo/cloudenly-ui/src/components/Menu";
import CardFooter from "@/components/CardFooter";
import RightSideBar from "@/components/RightSideBar";
import CommentsBox from "@/components/commentsBox";
import paramsMixin from "@/utilities/paramsMixin";
import timeFormatMixin from "@/utilities/timeFormatMixin";

export default {
  mixins: [paramsMixin, timeFormatMixin],
  components: {
    BackButton,
    Breadcrumb,
    Badge,
    RightSideBar,
    CommentsBox,
    Table,
    CTextarea,
    Card,
    Menu,
    CardFooter,
  },
  data() {
    return {
      isLoading: true,
      showDetails: true,
      loadingTable: true,
      breadcrumbs: [
        {
          disabled: false,
          text: "Performance",
          href: "Performance",
          id: "Performance",
        },
        {
          disabled: false,
          text: "Performance Appraisal",
          href: "Performance Appraisal",
          id: "PerformanceAppraisal",
        },
        {
          disabled: false,
          text: "Appraisal Details",
          href: "AppraisalDetails",
          id: "AppraisalDetails",
        },
      ],
      AppHeader: [
        { title: "", value: "photo", width: "6" },
        { title: "Name", value: "name" },
        { title: "Self Appraisal", value: "selfAppraisal" },
        { title: "Line Manager", value: "managerAppraisal" },
        { title: "Status", value: "status" },
        { title: "Approved Score", value: "approvedScore" },
        { title: "Date Submitted", value: "submitted" },
        { title: "", value: "id", image: true },
      ],
      items: [],
      showBulk: false,
      bulkActionOptions: [
        {
          icon: "icon-check",
          text: "Approve",
          action: "approve Appraisal",
        },
      ],
      paginationList: {
        page: 1,
        lastPage: 1,
        total: 1,
        from: 1,
        to: 1,
      },
      agreementData: {
        title: null,
        cycle: null,
        description: null,
        period: { start: null, end: null },
      },
      templateArr: [],
      agreementCycles: [],
      employees: [],
      sharedAppraisals: [],
      cyclesArr: [],
      commentModal: false,
      allComments: [],
      receiverId: "",
      commentAgreementId: "",
      loadComments: false,
      comment: "",
      selectedEmployees: [],
      extendedCycle: {},
      activeCycle: "",
      searchQuery: "",
      metaData: {},
      itemsPerPage: null,
      numberOfPage: null,
    };
  },

  methods: {
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },

    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },

    queryUpdate() {
      this.loadingTable = true;
      const search = this.searchQuery ? `${this.searchQuery}` : "";
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.getUsersDirectReports(`${pageNumber}${itemPage}${search}`);
    },

    handleSearch(value) {
      this.searchQuery = `&search=${value}`;
      this.loadingTable = true;
      this.getUsersDirectReports(this.searchQuery);
    },

    viewCycle(value) {
      this.agreementCycles.forEach((cycle) => {
        if (cycle.id === value) {
          this.activeCycle = cycle.name;
        }
      });
      this.sharedAppraisals = [];
      this.employees = [];
      this.loadingTable = true;
      this.getDirectReports();
    },

    reload() {
      this.loadingTable = true;
      this.getDirectReports();
    },

    displayRow(value) {
      if (value.length > 0) {
        this.selectedEmployees = value.filter((employee) => (employee.managerAppraisal !== "---" && !employee.approved));
        if (this.selectedEmployees.length > 0) {
          this.showBulk = true;
        }
      } else {
        this.showBulk = false;
      }
    },

    performBulkAction(methodFunc) {
      if (methodFunc === "return Appraisal") {
        this.returnAppraisal("");
      }

      if (methodFunc === "approve Appraisal") {
        this.approveAppraisal("");
      }
    },

    handleShowDetails() {
      this.showDetails = !this.showDetails;
    },

    getTemplate() {
      const currentYear = this.$route.params.year;
      this.$_getPerfomanceTemplate("").then((response) => {
        const templatesArr = response.data.PerformanceTemplate;

        templatesArr.filter((template) => {
          if (template.year === currentYear) {
            this.agreementData.title = template.title;
            this.agreementData.cycle = template.title;
            this.agreementData.description = template.agreement_description;
            this.agreementData.period.start = this.setDates(
              template.appraisal_cycle.agreement_start
            ).toISOString();
            this.agreementData.period.end = this.setDates(
              template.appraisal_cycle.agreement_end
            ).toISOString();
          }
          return {};
        });
      });
    },

    getAppraisalCycle() {
      const today = new Date();

      this.$_getAppraisalCycle(this.appraisalQuery()).then((response) => {
        const appraisal = response.data.AppraisalCycle;
        const { extension } = response.data;

        this.extendedCycle = extension;

        this.agreementData.description = appraisal[0].description;

        if (appraisal[0].cycles.length > 0) {
          const appraisalCycle = appraisal[0].cycles.sort(
            (a, b) =>
              this.setDates(a.appraisal_starts) -
              this.setDates(b.appraisal_starts)
          );

          appraisalCycle.map((cycle) => {
            this.cyclesArr.push(cycle.id);

            const cycleStartDate = new Date(
              this.formatDateTime(cycle.appraisal_starts)
            );

            const cycleEndDate = new Date(
              this.formatDateTime(cycle.appraisal_ends)
            );

            cycleEndDate.setDate(cycleEndDate.getDate() + 1);

            this.agreementCycles.push({
              name: cycle.name,
              start: this.formatDateTime(cycle.appraisal_starts),
              end: this.formatDateTime(cycle.appraisal_ends),
              active: today >= cycleStartDate && today <= cycleEndDate,
              id: cycle.id,
            });

            if (today >= cycleStartDate && today <= cycleEndDate) {
              this.activeCycle = cycle.name;
            }

            return {};
          });
        }
        this.getDirectReports();
      });
    },

    getSelfScore(userId) {
      let score;
      this.sharedAppraisals.forEach((appraisal) => {
        if (
          appraisal.year === this.$route.params.year.toString() &&
          appraisal.employeeId === userId
        ) {
          const tabIndex = this.cyclesArr.indexOf(appraisal.cycleId, 0);

          if (tabIndex === this.cyclesArr.length - 1) {
            score = appraisal.selfScore;
          } else {
            score = appraisal.employeeFlag;
          }
        }
        return {};
      });
      return score || "---";
    },

    getManagerScore(userId) {
      let score;
      this.sharedAppraisals.forEach((appraisal) => {
        if (
          appraisal.year === this.$route.params.year.toString() &&
          appraisal.employeeId === userId
        ) {
          const tabIndex = this.cyclesArr.indexOf(appraisal.cycleId, 0);

          if (tabIndex === this.cyclesArr.length - 1) {
            score = appraisal.managerScore;
          } else {
            score = appraisal.managerFlag;
          }
        }
        return {};
      });
      return score || "---";
    },

    getStatus(userId) {
      let status;
      this.sharedAppraisals.filter((appraisal) => {
        if (
          appraisal.year === this.$route.params.year.toString() &&
          appraisal.employeeId === userId
        ) {
          status = appraisal.appraisalStatus;
        }
        return {};
      });
      return status || "not submitted";
    },

    getReviewType(userId) {
      let status;
      this.sharedAppraisals.filter((appraisal) => {
        if (
          appraisal.year === this.$route.params.year.toString() &&
          appraisal.employeeId === userId
        ) {
          status = appraisal.reviewType;
        }
        return {};
      });
      return status || "";
    },

    getApprovedScore(userId) {
      let score;
      this.sharedAppraisals.forEach((appraisal) => {
        if (
          appraisal.year === this.$route.params.year.toString() &&
          appraisal.employeeId === userId &&
          appraisal.appraisalStatus === "submitted"
        ) {
          const tabIndex = this.cyclesArr.indexOf(appraisal.cycleId, 0);

          if (tabIndex === this.cyclesArr.length - 1) {
            score = appraisal.managerScore;
          } else {
            score = appraisal.managerFlag;
          }
        }
        return {};
      });
      return score || "---";
    },

    getDateSubmitted(userId) {
      let score;
      this.sharedAppraisals.forEach((appraisal) => {
        if (
          appraisal.year === this.$route.params.year.toString() &&
          appraisal.employeeId === userId
        ) {
          score = appraisal.dateSubmitted;
        }
        return {};
      });
      return score || "";
    },

    getGoalId(userId) {
      let score;
      this.sharedAppraisals.forEach((appraisal) => {
        if (
          appraisal.year === this.$route.params.year.toString() &&
          appraisal.employeeId === userId
        ) {
          score = appraisal.goalId;
        }
        return {};
      });
      return score || "";
    },

    getCycleName(userId) {
      let score;
      this.sharedAppraisals.forEach((appraisal) => {
        if (
          appraisal.year === this.$route.params.year.toString() &&
          appraisal.employeeId === userId
        ) {
          score = appraisal.cycleName;
        }
        return {};
      });
      return score || "";
    },

    getAppraisalId(userId) {
      let score;
      this.sharedAppraisals.forEach((appraisal) => {
        if (
          appraisal.year === this.$route.params.year.toString() &&
          appraisal.employeeId === userId
        ) {
          score = appraisal.id;
        }
        return {};
      });
      return score || "";
    },

    getUsersDirectReports(params) {
      const currentYear = new Date().getFullYear();
      this.loadingTable = true;
      this.employees = [];

      this.$_getDirectReportsAgreementsList(
        this.$AuthUser.id,
        currentYear,
        params
      )
        .then((response) => {
          const employees = response.data.directReports;
          this.metaData = response.data.meta;

          employees.forEach((data) => {
            this.employees.push({
              id: data.userId,
              name: `${data.fname} ${data.lname}`,
              photo: data.photo,
              function: data.function,
              designation: data.designation,
              selfAppraisal: this.getSelfScore(data.userId),
              managerAppraisal: this.getManagerScore(data.userId),
              status: this.getStatus(data.userId),
              approvedScore: this.getApprovedScore(data.userId),
              submitted: this.getDateSubmitted(data.userId),
              reviewType: this.getReviewType(data.userId),
              goal: this.getGoalId(data.userId),
              cycleName: this.getCycleName(data.userId),
              year: this.$route.params.year,
              appraisalId: this.getAppraisalId(data.userId),
              agreementId: data.agreementId,
            });
            return {};
          });
          this.isLoading = false;
          this.loadingTable = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.loadingTable = false;
        });
    },

    getDirectReports() {
      this.$_getDirectReportAppraisals(this.$AuthUser.id).then((response) => {
        const appraisalData = response.data.sharedAppraisals;

        appraisalData.map((appraisal) => {
          appraisal.shared_appraisal.map((shared) => {
            if (shared.appraisal.cycleId) {
              this.agreementCycles.filter((cycle) => {
                if (
                  cycle.id === shared.appraisal.cycleId &&
                  cycle.name === this.activeCycle
                ) {
                  this.sharedAppraisals.push({
                    id: shared.appraisal.id,
                    appraisalAgreement: shared.appraisal.appraisal_agreement,
                    appraisalState: shared.appraisal.appraisal_state,
                    appraisalStatus: shared.appraisal.appraisal_status,
                    approvedScore: shared.appraisal.approved_score,
                    dateSubmitted: shared.appraisal.date_submitted,
                    agreementId: shared.appraisal.employeeAgreementId,
                    employeeId: shared.appraisal.employeeId,
                    managerScore: shared.appraisal.manager_score,
                    selfScore: shared.appraisal.self_score,
                    reviewType: shared.appraisal.reviewType,
                    year: shared.appraisal.year,
                    goalId: shared.appraisal.goalId,
                    employeeFlag: shared.appraisalSummary.employeeFlag,
                    managerFlag: shared.appraisalSummary.managerFlag,
                    cycleId: cycle.id,
                    cycleName: cycle.name,
                  });
                }
                return {};
              });
            }
            return {};
          });
          return {};
        });

      this.getUsersDirectReports('');
      });
    },

    approveAppraisal(appraisalId) {
      let requestPayload;
      const appraisalIds = [];

      if (appraisalId === "") {
        this.selectedEmployees.forEach((employee) => {
          if (employee.appraisalId !== "") {
            appraisalIds.push(employee.appraisalId);
          }
        });

        requestPayload = {
          appraisalIds,
          appraisal_status: "submitted",
          userId: this.$AuthUser.id,
          orgId: this.$orgId,
        };
      } else {
        requestPayload = {
          appraisalIds: [appraisalId],
          appraisal_status: "submitted",
          userId: this.$AuthUser.id,
          orgId: this.$orgId,
        };
      }

      this.$_approveAppraisal(requestPayload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.templateArr = [];
          this.cyclesArr = [];
          this.agreementCycles = [];
          this.sharedAppraisals = [];
          this.employees = [];
          this.isLoading = true;
          this.getTemplate();
          this.getAppraisalCycle();
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message, { duration: 5000 });
        });
    },

    returnAppraisal(appraisalId) {
      const requestPayload = {
        appraisalIds: [appraisalId],
      };
      this.$_returnAppraisal(requestPayload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.templateArr = [];
          this.cyclesArr = [];
          this.agreementCycles = [];
          this.sharedAppraisals = [];
          this.employees = [];
          this.isLoading = true;
          this.getTemplate();
          this.getAppraisalCycle();
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message, { duration: 5000 });
        });
    },

    getComments(agreementId, userId) {
      this.commentModal = true;
      this.allComments = [];
      this.receiverId = userId;
      this.commentAgreementId = agreementId;
      this.loadComments = true;
      this.$_getAgreementComments(agreementId)
        .then((response) => {
          const { comments } = response.data;
          const unread = [];
          this.loadComments = false;
          comments.forEach((comment) => {
            this.allComments.push({
              message: comment.comment,
              time: this.formatCommentTime(comment.date),
              date: this.formatCommentDate(comment.date),
              commentId: comment.id,
              isRead: comment.readStatus,
              receiver: comment.receiver,
              sender: comment.sender,
            });
            if (comment.readStatus === false) {
              unread.push(comment.readStatus);
            }
          });
          this.unreadComments = unread.length;
        })
        .catch((error) => {
          if (error.response.data.message !== "") {
            this.loadComments = false;
          }
        });
    },
    sendComment() {
      const payload = {
        performanceAgreementId: this.commentAgreementId,
        senderId: this.$AuthUser.id,
        receiverId: this.receiverId,
        comment: this.comment,
        sendTo: "admin",
      };
      this.$_postAgreementComments(payload).then((response) => {
        this.$toasted.success(response.data.message, { duration: 5000 });
        this.getComments(this.commentAgreementId);
        this.comment = "";
      });
    },

    deleteComments(id) {
      this.$_deleteAgreementComments(id)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.getComments(this.commentAgreementId);
        })
        .then((err) => {
          this.$toasted.error(err.response.data.message, { duration: 5000 });
        });
    },

    formatCommentTime(date) {
      const newDate = new Date(date);
      const hours = newDate.getUTCHours();
      const minutes = newDate.getMinutes();
      return `${1 + ((hours - 1) % 12)}:${minutes
        .toString()
        .padStart(2, "0")} ${hours > 11 ? "PM" : "AM"}`;
    },
    formatCommentDate(date) {
      const newDate = new Date(date);
      return newDate.toDateString();
    },
  },

  mounted() {
    this.getTemplate();
    this.getAppraisalCycle();
  },
};
</script>

<style scoped>
.pending {
  background: rgba(233, 147, 35, 0.08);
  color: #e99323;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
.approved {
  background: rgba(19, 181, 106, 0.08);
  color: #13b56a;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
.disapproved {
  background: rgba(234, 60, 83, 0.08);
  color: #ea3c53;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}

.submitted {
  background: rgba(50, 28, 59, 0.15);
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}

.not_submitted {
  background: rgba(234, 60, 83, 0.15);
  color: rgba(234, 60, 83, 1);
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
</style>
